export const socialMediaList = [
    {
        title: 'facebook',
        imageUrl: '/images/icon/fb.svg',
        href: 'https://www.facebook.com/profile.php?id=61566875482701&is_tour_dismissed'
    },
    {
        title: 'instagram',
        imageUrl: '/images/icon/ig.svg',
        href: 'https://www.instagram.com/wedpro0214/'
    },
    {
        title: 'tiktok',
        imageUrl: '/images/icon/tik_tok.svg',
        href: 'https://www.tiktok.com/@wedpro0214?is_from_webapp=1&sender_device=pc'
    },
    {
        title: 'x',
        imageUrl: '/images/icon/x.svg',
        href: 'https://x.com/wedpro0214'
    },
    {
        title: 'xiaohongshu',
        imageUrl: '/images/icon/xhs.svg',
        href: 'https://www.xiaohongshu.com/user/profile/66fb53f9000000001d02072f?xhsshare=CopyLink&appuid=66fb53f9000000001d02072f&apptime=1727772290'
    },
]