import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './home-service.styles.scss';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../api/axiosInstance';  // Import the axios instance

const HomeService = () => {
    const { t, i18n } = useTranslation();  // Get i18n instance for language detection

    // State to store the categories fetched from the API
    const [categories, setCategories] = useState([]);

    // Fetch categories from the API when the component mounts
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axiosInstance.get('/categories');
                setCategories(response.data);  // Store the fetched categories in state
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, []);  // Empty dependency array ensures the effect runs only once

    // Helper function to get the appropriate category name based on the current language
    const getCategoryName = (category) => {
        const currentLanguage = i18n.language;  // Get the current language from i18next
        // Choose the category name based on the current language
        if (currentLanguage === 'en') {
            return category.translation.name_en;
        } else if (currentLanguage === 'cn') {
            return category.translation.name_cn;
        } else if (currentLanguage === 'jp') {
            return category.translation.name_jp;
        } else if (currentLanguage === 'my') {
            return category.translation.name_my;
        } 
        else {
            return category.translation.name_en;  // Fallback to English if language is not supported
        }
    };

    return (
        <div className="home-service-container">
            <div className="body-container">
                <h1 className="main-title">{t('services')}</h1>
                <div className="grid-container">
                    {
                        categories.map(category => (
                            <div className="content-container" key={category.id}>
                                <Link className="service-ring" to={`/categories/${category.id}`}>
                                    <div className="image-container" style={{ backgroundImage: `url("${category.imageUrl || ""}")` }} />
                                </Link>
                                {/* Use getCategoryName to dynamically select the appropriate name */}
                                <h4 className="title">{getCategoryName(category)}</h4>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default HomeService;
