import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AppRoutes from './routes'; // Import the routes
// Import any global contexts or providers if necessary

function App() {
  return (
    <>
      <Helmet>
        <title>WEDPRO</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <link href="https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet"></link>
      </Helmet>
      <Router>
        <AppRoutes />
      </Router>
    </>
  );
}

export default App;
