import { Link } from 'react-router-dom';
import './navbar.styles.scss';
import { useState, useEffect } from 'react';
import 'material-icons/iconfont/outlined.css';
import { LanguageList } from '../../../data/language';
import { useSetNav } from '../../../libs/hook/useSetNav';
import { setNavActive } from '../../../libs/function/setNavActive';
import DropdownContainer from '../dropdown-container/dropdown-container.component';
import NavRequestLogin from '../../nav-request-login/nav-request-login.component';
import { useTranslation } from 'react-i18next';
import NavAfterLogin from '../../nav-after-login/nav-after-login.component';
import { useSelector } from 'react-redux';
import { PathList } from '../../../data/nav-link.data';

const Navbar = ({ classes = '' }) => {
  const { t, i18n } = useTranslation();
  const [toggleUser, setToggleUser] = useState(false);
  const [toggleLang, setToggleLang] = useState(false);

  // Get user info from Redux state
  const auth = useSelector((state) => state.auth);
  const isLogin = !!auth.token;
  const user = auth.user;
  const username = user ? user.username : null;

  const currentPath = useSetNav();

  // Determine navigation links based on whether the user has completed the questionnaire
  const userDetails = useSelector((state) => state.auth.user);
  const navLinks = userDetails && userDetails.fill_questionnaire === 1
    ? [
        { title: 'home', url: '/', pathname: '' },
        { title: 'my_wedding', url: '/manage-wedding', pathname: 'manage-wedding' },
      ]
    : [
        { title: 'home', url: '/', pathname: '' },
        { title: 'my_wedding', url: '/questionnaire', pathname: 'questionnaire' },
      ];

  // State to keep track of the current language name
  const [currentLanguage, setCurrentLanguage] = useState(LanguageList[i18n.language] || LanguageList.en);

  useEffect(() => {
    setCurrentLanguage(LanguageList[i18n.language] || LanguageList.en);
  }, [i18n.language]);

  const handleToggleUser = (e) => {
    e.stopPropagation(); // Prevent propagation to avoid closing the dropdown too soon
    setToggleUser((prevState) => !prevState);
    setToggleLang(false); // Ensure only one dropdown is open at a time
  };

  const handleToggleLang = (e) => {
    e.stopPropagation(); // Prevent propagation to avoid closing the dropdown too soon
    setToggleLang((prevState) => !prevState);
    setToggleUser(false); // Ensure only one dropdown is open at a time
  };

  const handleChangeLang = (e) => {
    e.stopPropagation(); // Prevent propagation when changing language
    const userLang = e.target.dataset.lang;
    if (!userLang) return setToggleLang(false);

    i18n.changeLanguage(userLang).then(() => {
      setCurrentLanguage(LanguageList[userLang]);
    });

    setToggleLang(false);
  };

  return (
    <div
      className={`navbar-container ${classes}`}
      onClick={() => {
        // Close all dropdowns when clicking outside
        setToggleUser(false);
        setToggleLang(false);
      }}
    >
      <div className="body-container" onClick={(e) => e.stopPropagation()}>
        <div className="nav-link-container">
          <Link to={PathList.home.url} className="logo-image">
            <img src="/images/logo/theme-white.svg" alt="" className="logo-image" />
            WEDPRO
          </Link>
          {navLinks.map((navLink) => (
            <Link
              key={navLink.title}
              to={navLink.url}
              className={`nav-link ${setNavActive(currentPath, navLink.url)}`}
            >
              {t(navLink.title)}
            </Link>
          ))}
        </div>

        <div className="function-container">
          {/* User Account Dropdown */}
          <div className="nav-link account">
            <div className="link-button" onClick={handleToggleUser}>
              <span className="material-icons-outlined">account_circle</span>
              <p>{username ? username : t('user')}</p>
            </div>
            {toggleUser && (
              <DropdownContainer
                classes="dropdown-user"
                isToggle={toggleUser}
                setToggle={setToggleUser}
              >
                {isLogin ? (
                  <NavAfterLogin setToggle={setToggleUser} />
                ) : (
                  <NavRequestLogin setToggle={setToggleUser} />
                )}
              </DropdownContainer>
            )}
          </div>

          {/* Language Dropdown */}
          <div className="nav-link">
            <div className="link-button" onClick={handleToggleLang}>
              <span className="material-icons-outlined">language</span>
              <b>{currentLanguage}</b>
            </div>
            {toggleLang && (
              <DropdownContainer
                isToggle={toggleLang}
                setToggle={setToggleLang}
                classes="dropdown-language"
              >
                {Object.keys(LanguageList).map((langKey) => (
                  <div
                    key={langKey}
                    className="language"
                    data-lang={langKey}
                    onClick={handleChangeLang}
                  >
                    {LanguageList[langKey]}
                  </div>
                ))}
              </DropdownContainer>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
