// src/pages/sign-up/sign-up.component.jsx

import React from 'react';
import './sign-up.styles.scss';
import SubHeader from '../../components/shared/sub-header/sub-header.component';
import SignUpForm from '../../components/sign-up-form/sign-up-form.component';
import { useTranslation } from 'react-i18next'; 

const SignUp = () => {
    const { t } = useTranslation();

    return (
        <div className="sign-up-body-container">
            <div className="sign-up-header-container">
                <SubHeader>
                    <div className="title-center">
                        <h1 className='main-header-title'>{t('sign_up')}</h1> 
                    </div>
                </SubHeader>
            </div>
            <SignUpForm />
        </div>
    );
}

export default SignUp;
