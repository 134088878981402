import './login-form.styles.scss';

import { Link } from 'react-router-dom';
import LoginFormBody from '../login-form-body/login-form-body.component';
import { PathList } from '../../data/nav-link.data';
import { useTranslation } from 'react-i18next'; 

const LoginForm = () => {    
    const { t } = useTranslation(); 

    return (
        <div className="login-form-container">
            <div className="form-container">
                <div className="form-card">
                    <h2 className="title">{t('welcome')}</h2> 
                    
                    {/* Form */}
                    <LoginFormBody />
                    
                    {/* Sign Up */}
                    <p className="sign-up-container">
                        {t('dont_have_account')}{' '}
                        <Link to={PathList.signup.url} className="link">
                            {t('sign_up')}
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default LoginForm;
