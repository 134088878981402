import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './home-latest-news.styles.scss';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../api/axiosInstance';  // Assuming axiosInstance is set up

const HomeLatestNews = () => {
  const { t, i18n } = useTranslation();
  const [newsList, setNewsList] = useState([]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axiosInstance.get('/news');
        setNewsList(response.data);
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    };

    fetchNews();
  }, []);

  // Helper function to get the appropriate news title based on the current language
  const getNewsTitle = (news) => {
    const currentLanguage = i18n.language;
    if (currentLanguage === 'en') {
      return news.translation.name_en;
    } else if (currentLanguage === 'cn') {
      return news.translation.name_cn;
    } else if (currentLanguage === 'jp') {
      return news.translation.name_jp;
    } else if (currentLanguage === 'my') {
      return news.translation.name_my;
    } else {
      return news.translation.name_en;  // Fallback to English
    }
  };

  return (
    <div className="home-latest-news">
      <div className="body-container">
        <h1 className="main-title">{t('latest_news')}</h1>
        <div className="grid-container">
          {newsList.map((news) => (
            <div className="card-container" key={news.id}>
              <Link className="card-content" to={`/news/${news.id}`}>
                <div
                  className="image"
                  style={{
                    backgroundImage: `url("${news.imageUrl || '/images/default/latest-news.png'}")`,
                  }}
                />
                <div className="title">{getNewsTitle(news)}</div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeLatestNews;
