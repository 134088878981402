import './default-loading.styles.scss';
import { Oval } from 'react-loader-spinner';

const DefaultLoading = () => {
    return (
        <div className="loading-container">
            <Oval
                visible={true}
                height="80"
                width="80"
                color="#403d3a"
                secondaryColor='#D7D7D7'
                ariaLabel="oval-loading"
            />
        </div>
    )
}

export default DefaultLoading