import { Outlet } from 'react-router-dom';
import './layout-main.styles.scss';
import Navbar from '../navbar/navbar.component';
import Footer from '../footer/footer.component';

const LayoutMain = () => {
    return (
      <>
        <div className="layout-body-container">
          <Navbar />     
          <div className="adjust-nav">
            <Outlet />
          </div>
        </div>        
        <Footer />
      </>
    )
}

export default LayoutMain