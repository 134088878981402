// src/pages/forget-password/forget-password.component.jsx

import './forget-password.styles.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ForgetPasswordForm from '../../components/forget-password-form/forget-password-form.component';
import SubHeader from '../../components/shared/sub-header/sub-header.component';

const ForgetPassword = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="forget-password-body-container">
                <div className="forget-password-header-container">
                    <SubHeader>
                        <div className="title-center">
                            <h1 className='main-header-title'>{t('reset_password')}</h1> 
                        </div>
                    </SubHeader>
                </div>        
                <div className="forget-password-form-container">

                    <div className="form-container">
                        <div className="form-card">
                            <h2 className="title">{t('reset_password')}</h2> 
                            <ForgetPasswordForm />                            
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default ForgetPassword;
