// src/store/root-reducer.js

import { combineReducers } from 'redux';
import questionnaireReducer from './questionnaire/questionnaire.reducer'; 
import authReducer from './auth/auth.reducer';

const rootReducer = combineReducers({
  auth: authReducer, 
  questionnaire: questionnaireReducer,
});

export default rootReducer;
