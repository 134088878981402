export const LoginFormNames = {
    username: 'username',
    password: 'password',
    email: 'email',
}

export const LoginFormErrorKeys = {
    'invalid_user': 'invalid',
    'username_short': 'username_short',
}

export const LoginFormErrors = {
    [LoginFormErrorKeys.invalid_user]: 'Invalid username/email or password.',
    [LoginFormErrorKeys.username_short]: 'Username should at least 6 characters.',
}