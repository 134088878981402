import axios from 'axios';

// Get the CSRF token from the meta tag
const csrfMeta = document.querySelector('meta[name="csrf-token"]');
const csrfToken = csrfMeta ? csrfMeta.getAttribute('content') : '';

// Use an environment variable for the base URL
const baseURL = 'https://wed-pro.com/api/';

// Configure Axios to include the CSRF token in all requests
const axiosInstance = axios.create({
  baseURL,
  headers: {
    'X-CSRF-TOKEN': csrfToken,
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  }
});

export default axiosInstance;
