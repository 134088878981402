import './nav-after-login.styles.scss';
import { useDispatch } from 'react-redux';
import { logout } from '../../store/auth/auth.actions';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom

const NavAfterLogin = ({ setToggle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useNavigate

  const handleLogout = () => {
    // Dispatch the logout action
    dispatch(logout());

    // Close the dropdown after logout
    if (setToggle) {
      setToggle(false);
    }

    // Redirect to login page after logout
    navigate('/login');
  };

  return (
    <div className="nav-after-login">
      <div className="logout-button" onClick={handleLogout}>
        <span className="material-icons-outlined">logout</span>
        <p>Logout</p>
      </div>            
    </div>
  );
};

export default NavAfterLogin;
