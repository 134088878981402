import React, { useState, useEffect } from 'react';
import './login-form-body.styles.scss';
import {
  LoginFormErrorKeys,
  LoginFormErrors,
  LoginFormNames,
} from '../../data/login-form';
import FormInput from '../shared/form-input/form-input.component';
import FormPassword from '../shared/form-password/form-password.component';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import isEmail from 'validator/lib/isEmail';
import { PathList } from '../../data/nav-link.data';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../store/auth/auth.actions';

const LoginFormBody = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth) || {};

  // Load saved credentials from localStorage
  const savedUsername = localStorage.getItem('username') || '';
  const savedPassword = localStorage.getItem('password') || '';

  // Form data state with Remember Me credentials from localStorage if available
  const [formData, setFormData] = useState({
    [LoginFormNames.username]: savedUsername,
    [LoginFormNames.password]: savedPassword,
  });
  const [rememberMe, setRememberMe] = useState(savedUsername !== ''); // true if credentials exist
  const [errorMessage, setErrorMessage] = useState([]);

  const minUsernameLength = 6;

  // Set data in the formData when user inputs the value
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    const purifiedName = DOMPurify.sanitize(name.trim());
    const purifiedValue = DOMPurify.sanitize(value.trim());
    setFormData((prevState) => ({
      ...prevState,
      [purifiedName]: purifiedValue,
    }));
  };

  // Handle Remember Me Checkbox
  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  // Validate the user and submit the form
  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage([]);

    // Check if the form is empty
    if (!formData[LoginFormNames.username] || !formData[LoginFormNames.password]) {
      return createError(LoginFormErrorKeys.invalid_user);
    }

    // Check username valid length
    if (formData[LoginFormNames.username].length < minUsernameLength) {
      createError(LoginFormErrorKeys.username_short);
      return;
    }

    // Check if user is using email or username to login
    const isMail = isEmail(formData[LoginFormNames.username]);
    const userKey = isMail ? 'email' : 'username';


    const validatedFormData = {
      [userKey]: formData[LoginFormNames.username],
      [LoginFormNames.password]: formData[LoginFormNames.password],
    };

    // Store credentials if "Remember Me" is checked
    if (rememberMe) {
      localStorage.setItem('username', formData[LoginFormNames.username]);
      localStorage.setItem('password', formData[LoginFormNames.password]);
    } else {
      localStorage.removeItem('username');
      localStorage.removeItem('password');
    }

    // Dispatch login action with Remember Me flag
    dispatch(login(validatedFormData, rememberMe));
  };

  // Handle errors and success from Redux state
  useEffect(() => {
    if (auth.error) {
      setErrorMessage([auth.error]);
    }

    if (auth.token) {
      // Redirect to dashboard
      window.location.href = '/'; // Change the path as needed
    }
  }, [auth.error, auth.token]);

  // Create error names and insert into errorMessage hook
  const createError = (errorKey) => {
    setErrorMessage((prevState) => [...prevState, t(errorKey)]);
  };

  return (
    <div className="login-form-body-container">
      <form className="form-box" onSubmit={handleSubmit}>
        {/* Username / Email */}
        <FormInput
          id="username"
          label={t('email_or_username')}
          placeholder={t('enter_username_or_email')}
          name={LoginFormNames.username}
          value={formData[LoginFormNames.username]}
          onChange={handleOnChange}
          errorMessage={
            errorMessage.includes(t(LoginFormErrors[LoginFormErrorKeys.username_short]))
              ? t(LoginFormErrors[LoginFormErrorKeys.username_short])
              : null
          }
          required
        />

        <FormPassword
          id="password"
          label={t('password')}
          placeholder={t('enter_password')}
          name={LoginFormNames.password}
          value={formData[LoginFormNames.password]}
          onChange={handleOnChange}
          errorMessage={
            errorMessage.includes(t(LoginFormErrors[LoginFormErrorKeys.invalid_user]))
              ? t(LoginFormErrors[LoginFormErrorKeys.invalid_user])
              : null
          }
          required
        />

        {/* Remember me & forget password */}
        <div className="login-features-container">
          <div className="remember-container">
            <input
              type="checkbox"
              id="remember"
              checked={rememberMe}
              onChange={handleRememberMeChange}
            />
            <label htmlFor="remember" className="text-sm">
              {t('remember_me')}
            </label>
          </div>
          <Link to={PathList.forgetPassword.url} className="forget-password">
            {t('forgot_password')}
          </Link>
        </div>
        {/* Error message */}
        {errorMessage.length !== 0 && (
          <div className="error-message-container">
            {errorMessage.map((msg, index) => (
              <p key={index} className="error">
                * {msg}
              </p>
            ))}
          </div>
        )}
        {/* Submit button */}
        <div className="submit-button-container">
          <button type="submit" disabled={auth.isLoading}>
            {auth.isLoading ? t('loading') : t('login')}
          </button>
        </div>
      </form>
      {/* Alternate way to login */}
      <div className="separator">{t('login_with')}</div>
      <div className="alt-login-container">
        <button className="alt-button">
          {/* Google SVG Icon */}
          <svg
            className="w-5 h-5 mr-2"
            viewBox="0 0 24 24"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
              fill="#4285F4"
            />
            <path
              d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
              fill="#34A853"
            />
            <path
              d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
              fill="#FBBC05"
            />
            <path
              d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
              fill="#EA4335"
            />
          </svg>
          {t('google')}
        </button>
        <button className="alt-button">
          {/* Facebook SVG Icon */}
          <svg
            className="w-5 h-5 mr-2"
            viewBox="0 0 24 24"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"
              fill="#1877F2"
            />
          </svg>
          {t('facebook')}
        </button>
      </div>
    </div>
  );
};

export default LoginFormBody;
