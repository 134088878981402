import AuthActionTypes from './auth.types';
import axiosInstance from '../../api/axiosInstance';

// Action to initiate login request
export const loginRequest = () => ({
  type: AuthActionTypes.LOGIN_REQUEST,
});

// Action for successful login
export const loginSuccess = (token, user, rememberMe, username, password) => ({
  type: AuthActionTypes.LOGIN_SUCCESS,
  payload: { token, user, rememberMe, username, password },
});

// Action for failed login
export const loginFailure = (error) => ({
  type: AuthActionTypes.LOGIN_FAILURE,
  payload: error,
});

// Thunk action to perform login
export const login = (credentials, rememberMe) => {
  return async (dispatch) => {
    dispatch(loginRequest());

    try {
      const response = await axiosInstance.post('/login', credentials);

      if (response.status === 200) {
        const { token, user } = response.data;

        // Store user credentials if "Remember Me" is checked
        if (rememberMe) {
          localStorage.setItem('token', token);
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('username', credentials.username || credentials.email);
          localStorage.setItem('password', credentials.password);
          localStorage.setItem('rememberMe', 'true');
        } else {
          localStorage.removeItem('username');
          localStorage.removeItem('password');
          localStorage.setItem('rememberMe', 'false');
        }

        dispatch(loginSuccess(token, user, rememberMe, credentials.username, credentials.password));
      }
    } catch (error) {
      let errorMsg = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        errorMsg = error.response.data.message;
      }
      dispatch(loginFailure(errorMsg));
    }
  };
};

// Action to logout
export const logout = () => {
  // Clear local storage on logout
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  const rememberMe = localStorage.getItem('rememberMe') === 'true';

  if (!rememberMe) {
    localStorage.removeItem('username');
    localStorage.removeItem('password');
  }

  return {
    type: AuthActionTypes.LOGOUT,
  };
};



export const updateUserData = (updatedData) => ({
  type: AuthActionTypes.UPDATE_USER_DATA,
  payload: updatedData,
});
