// src/components/shared/form-date-picker/form-date-picker.component.jsx

import React from 'react';
import './form-date-picker.styles.scss';
import PropTypes from 'prop-types';

const FormDatePicker = ({ label, id, name, onChange, value, errorMessage = null }) => {

    const getTodayDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0'); 
        const dd = String(today.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
    };

    const getMinDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear() - 120;
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const dd = String(today.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`; 
    };
    
    const handleChange = (e) => {
        const dateValue = e.target.value;
        onChange({ name, value: dateValue });
    };

    return (
        <div className="datepicker-container">
            <label htmlFor={id}>{label}</label>
            <input 
                id={id} 
                className='input' 
                name={name}
                type='date'
                min={getMinDate()}
                max={getTodayDate()}
                value={value}
                onChange={handleChange}
                required
            />
            {
                errorMessage &&
                <small className="error-message">* {errorMessage}</small>
            }
        </div>
    )
}

FormDatePicker.propTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    errorMessage: PropTypes.string,
};

export default FormDatePicker;
