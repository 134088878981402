// src/data/terms-and-conditions.js

export const tncLists = [
    {
        title: {
            en: 'Article 1 (Purpose)',
            cn: '第一条（目的）',
            jp: '第1条（目的）',
            my: 'Artikel 1 (Tujuan)'
        },
        content: {
            en: [
                'These terms and conditions specify matters that should be complied with between the wedding platform (hereinafter referred to as "our company") and users regarding the use of the wedding information and scheduling service (hereinafter referred to as "the Service") provided by our company. The Service allows users to access wedding-related information and plan their wedding schedule based on customizable events.',
            ],
            cn: [
                '这些条款和条件规定了婚礼平台（以下简称“本公司”）与用户之间在使用本公司提供的婚礼信息和日程安排服务（以下简称“服务”）时应遵守的事项。该服务允许用户访问与婚礼相关的信息，并根据可定制的活动计划他们的婚礼日程。'
            ],
            jp: [
                'これらの利用規約は、結婚プラットフォーム（以下「当社」といいます）とユーザーとの間で、当社が提供する結婚情報およびスケジュールサービス（以下「サービス」といいます）の利用に関して遵守すべき事項を規定しています。サービスを通じてユーザーは結婚関連情報にアクセスし、カスタマイズ可能なイベントに基づいて結婚式のスケジュールを計画することができます。'
            ],
            my: [
                'Syarat dan ketentuan ini menetapkan perkara-perkara yang perlu dipatuhi antara platform perkahwinan (selepas ini disebut sebagai "syarikat kami") dan pengguna mengenai penggunaan perkhidmatan maklumat perkahwinan dan penjadualan (selepas ini disebut sebagai "Perkhidmatan") yang disediakan oleh syarikat kami. Perkhidmatan ini membolehkan pengguna mengakses maklumat berkaitan perkahwinan dan merancang jadual perkahwinan mereka berdasarkan acara yang boleh disesuaikan.'
            ]
        }
    },
    {
        title: {
            en: 'Article 2 (Definition of Users)',
            cn: '第二条（用户定义）',
            jp: '第2条（ユーザーの定義）',
            my: 'Artikel 2 (Definisi Pengguna)'
        },
        content: {
            en: [
                'In these terms and conditions, "users" refer to all individuals and entities that use the Service.',
            ],
            cn: [
                '在这些条款和条件中，“用户”指所有使用服务的个人和实体。'
            ],
            jp: [
                'これらの利用規約において、「ユーザー」とはサービスを利用するすべての個人および法人を指します。'
            ],
            my: [
                'Dalam syarat dan ketentuan ini, "pengguna" merujuk kepada semua individu dan entiti yang menggunakan Perkhidmatan.'
            ]
        }
    },
    {
        title: {
            en: 'Article 3 (Agreement to Terms and Conditions)',
            cn: '第三条（同意条款和条件）',
            jp: '第3条（利用規約への同意）',
            my: 'Artikel 3 (Persetujuan terhadap Syarat dan Ketentuan)'
        },
        content: {
            en: [
                'Users shall use the Service after agreeing to these terms and conditions. By using the Service, it shall be deemed that users have agreed to these terms and conditions.',
            ],
            cn: [
                '用户应在同意这些条款和条件后使用服务。通过使用服务，将被视为用户已同意这些条款和条件。'
            ],
            jp: [
                'ユーザーは、これらの利用規約に同意した後、サービスを利用するものとします。サービスを利用することにより、ユーザーはこれらの利用規約に同意したものとみなされます。'
            ],
            my: [
                'Pengguna hendaklah menggunakan Perkhidmatan selepas bersetuju dengan syarat dan ketentuan ini. Dengan menggunakan Perkhidmatan, ia akan dianggap bahawa pengguna telah bersetuju dengan syarat dan ketentuan ini.'
            ]
        }
    },
    {
        title: {
            en: 'Article 4 (Conditions of Use)',
            cn: '第四条（使用条件）',
            jp: '第4条（利用条件）',
            my: 'Artikel 4 (Syarat-syarat Penggunaan)'
        },
        content: {
            en: [
                'Users shall meet the following conditions when using the Service:',
                '1. Users shall not violate these terms and conditions.',
                '2. Users shall comply with the policies set by our company for using the Service.',
            ],
            cn: [
                '用户在使用服务时应符合以下条件：',
                '1. 用户不得违反这些条款和条件。',
                '2. 用户应遵守本公司为使用服务所制定的政策。'
            ],
            jp: [
                'ユーザーは、サービスを利用する際に以下の条件を満たすものとします：',
                '1. ユーザーは、これらの利用規約に違反してはなりません。',
                '2. ユーザーは、サービスの利用に当たり、当社が定めるポリシーを遵守するものとします。'
            ],
            my: [
                'Pengguna hendaklah memenuhi syarat-syarat berikut ketika menggunakan Perkhidmatan:',
                '1. Pengguna tidak boleh melanggar syarat dan ketentuan ini.',
                '2. Pengguna hendaklah mematuhi polisi yang ditetapkan oleh syarikat kami untuk menggunakan Perkhidmatan.'
            ]
        }
    },
    {
        title: {
            en: 'Article 5 (Prohibited Matters)',
            cn: '第五条（禁止事项）',
            jp: '第5条（禁止事項）',
            my: 'Artikel 5 (Perkara yang Dilarang)'
        },
        content: {
            en: [
                'Users shall not engage in the following acts when using the Service:',
                '1. Acts that violate laws, regulations, public order, or morals.',
                '2. Acts related to criminal activities.',
                '3. Acts that obstruct the operation of our Service.',
                '4. Acts that cause disadvantage or harm to other users.',
                '5. Acts that infringe upon the intellectual property rights of our company, other users, or third parties.',
                '6. Other acts that our company deems inappropriate.',
            ],
            cn: [
                '用户在使用服务时不得从事以下行为：',
                '1. 违反法律、法规、公共秩序或道德的行为。',
                '2. 与犯罪活动有关的行为。',
                '3. 妨碍本公司服务运营的行为。',
                '4. 对其他用户造成不利或伤害的行为。',
                '5. 侵犯本公司、其他用户或第三方知识产权的行为。',
                '6. 本公司认为不适当的其他行为。'
            ],
            jp: [
                'ユーザーは、サービスを利用する際に以下の行為を行ってはなりません：',
                '1. 法律、規則、公共秩序または道徳に違反する行為。',
                '2. 犯罪活動に関連する行為。',
                '3. 当社のサービス運営を妨害する行為。',
                '4. 他のユーザーに不利益または害を与える行為。',
                '5. 当社、他のユーザーまたは第三者の知的財産権を侵害する行為。',
                '6. 当社が不適切と判断するその他の行為。'
            ],
            my: [
                'Pengguna tidak boleh terlibat dalam tindakan berikut apabila menggunakan Perkhidmatan:',
                '1. Tindakan yang melanggar undang-undang, peraturan, ketenteraman awam, atau moral.',
                '2. Tindakan yang berkaitan dengan aktiviti jenayah.',
                '3. Tindakan yang menghalang operasi Perkhidmatan kami.',
                '4. Tindakan yang menyebabkan kerugian atau bahaya kepada pengguna lain.',
                '5. Tindakan yang melanggar hak harta intelek syarikat kami, pengguna lain, atau pihak ketiga.',
                '6. Tindakan lain yang dianggap tidak sesuai oleh syarikat kami.'
            ]
        }
    },
    {
        title: {
            en: 'Article 6 (Fees and Payment Methods)',
            cn: '第六条（费用和支付方式）',
            jp: '第6条（料金および支払い方法）',
            my: 'Artikel 6 (Yuran dan Kaedah Pembayaran)'
        },
        content: {
            en: [
                'Certain features of the Service may be subject to fees. Users shall pay these fees by the method determined by our company.',
            ],
            cn: [
                '服务的某些功能可能需要费用。用户应按照本公司确定的方式支付这些费用。'
            ],
            jp: [
                'サービスの特定の機能には料金がかかる場合があります。ユーザーは、当社が定める方法でこれらの料金を支払うものとします。'
            ],
            my: [
                'Beberapa ciri-ciri Perkhidmatan mungkin tertakluk kepada yuran. Pengguna hendaklah membayar yuran ini mengikut kaedah yang ditentukan oleh syarikat kami.'
            ]
        }
    },
    {
        title: {
            en: 'Article 7 (Temporary Suspension or Discontinuation of Service Provision)',
            cn: '第七条（服务提供的暂时中止或终止）',
            jp: '第7条（サービス提供の一時停止または終了）',
            my: 'Artikel 7 (Penangguhan atau Penghentian Sementara Penyediaan Perkhidmatan)'
        },
        content: {
            en: [
                'Our company may temporarily suspend or discontinue the provision of the Service without prior notice to users in the following cases:',
                '1. When system maintenance or repair of malfunctions is necessary.',
                '2. When the provision of the Service becomes difficult due to natural disasters or other force majeure.',
                '3. When our company deems temporary suspension or discontinuation necessary for any other reason.',
                ' ',
                'Our company shall not be responsible for any damage caused to users by the temporary suspension or discontinuation of the Service except in cases of intentional or gross negligence.'
            ],
            cn: [
                '在以下情况下，本公司可在未提前通知用户的情况下，暂时中止或终止服务的提供：',
                '1. 系统维护或修复故障是必要的。',
                '2. 由于自然灾害或其他不可抗力，服务的提供变得困难。',
                '3. 本公司认为因任何其他原因有必要暂时中止或终止。',
                ' ',
                '除非因故意或重大过失，本公司对因服务的暂时中止或终止给用户造成的任何损害不承担责任。'
            ],
            jp: [
                '当社は、以下の場合において、ユーザーに事前通知することなくサービスの提供を一時停止または終了することがあります：',
                '1. システムの保守または故障の修理が必要な場合。',
                '2. 自然災害やその他の不可抗力によりサービスの提供が困難になった場合。',
                '3. 当社が他の理由でサービスの一時停止または終了が必要と判断した場合。',
                ' ',
                '当社は、故意または重大な過失による場合を除き、サービスの一時停止または終了によりユーザーに生じた損害について責任を負いません。'
            ],
            my: [
                'Syarikat kami boleh menangguhkan atau menghentikan sementara penyediaan Perkhidmatan tanpa notis awal kepada pengguna dalam kes-kes berikut:',
                '1. Apabila penyelenggaraan sistem atau pembaikan kerosakan diperlukan.',
                '2. Apabila penyediaan Perkhidmatan menjadi sukar disebabkan oleh bencana alam atau kekuatan besar lain.',
                '3. Apabila syarikat kami menganggap penangguhan sementara atau penghentian perlu atas sebarang sebab lain.',
                ' ',
                'Syarikat kami tidak akan bertanggungjawab terhadap sebarang kerosakan yang disebabkan kepada pengguna akibat penangguhan sementara atau penghentian Perkhidmatan kecuali dalam kes kecuaian sengaja atau kasar.'
            ]
        }
    },
    {
        title: {
            en: 'Article 8 (Changes or Termination of Service Content)',
            cn: '第八条（服务内容的变更或终止）',
            jp: '第8条（サービス内容の変更または終了）',
            my: 'Artikel 8 (Perubahan atau Penghentian Kandungan Perkhidmatan)'
        },
        content: {
            en: [
                'Our company may change the content of the Service without prior notice to users.',
            ],
            cn: [
                '本公司可在未提前通知用户的情况下，更改服务的内容。'
            ],
            jp: [
                '当社は、ユーザーに事前通知することなく、サービスの内容を変更することがあります。'
            ],
            my: [
                'Syarikat kami boleh mengubah kandungan Perkhidmatan tanpa notis awal kepada pengguna.'
            ]
        }
    },
    {
        title: {
            en: 'Article 9 (Disclaimer)',
            cn: '第九条（免责声明）',
            jp: '第9条（免責事項）',
            my: 'Artikel 9 (Penafian)'
        },
        content: {
            en: [
                '1. Our company shall not be responsible for any damage caused to users due to their inability to use the Service.',
                '2. Our company shall not be responsible for the accuracy, applicability, or any other aspect of the information obtained by users through the Service.',
                '3. Our company shall not be responsible for any disputes between users. Users shall resolve any disputes among themselves.'
            ],
            cn: [
                '1. 本公司不对用户因无法使用服务而造成的任何损害负责。',
                '2. 本公司不对用户通过服务获得的信息的准确性、适用性或任何其他方面负责。',
                '3. 本公司不对用户之间的任何争议负责。用户应自行解决任何争议。'
            ],
            jp: [
                '1. 当社は、ユーザーがサービスを利用できないことに起因するいかなる損害についても責任を負いません。',
                '2. 当社は、ユーザーがサービスを通じて取得した情報の正確性、適用性、その他の側面について責任を負いません。',
                '3. 当社は、ユーザー間のいかなる紛争についても責任を負いません。ユーザーは、自己の間で紛争を解決するものとします。'
            ],
            my: [
                '1. Syarikat kami tidak akan bertanggungjawab terhadap sebarang kerosakan yang disebabkan kepada pengguna akibat ketidakmampuan mereka untuk menggunakan Perkhidmatan.',
                '2. Syarikat kami tidak akan bertanggungjawab terhadap ketepatan, kesesuaian, atau aspek lain maklumat yang diperoleh oleh pengguna melalui Perkhidmatan.',
                '3. Syarikat kami tidak akan bertanggungjawab terhadap sebarang pertikaian antara pengguna. Pengguna hendaklah menyelesaikan sebarang pertikaian di antara mereka sendiri.'
            ]
        }
    },
    {
        title: {
            en: 'Article 10 (Intellectual Property Rights)',
            cn: '第十条（知识产权）',
            jp: '第10条（知的財産権）',
            my: 'Artikel 10 (Hak Harta Intelek)'
        },
        content: {
            en: [
                'All intellectual property rights related to the Service belong to our company or third parties with legitimate rights. Users shall not engage in any act that infringes on these rights by using the Service.',
            ],
            cn: [
                '与服务相关的所有知识产权归本公司或拥有合法权利的第三方所有。用户不得通过使用服务从事任何侵犯这些权利的行为。'
            ],
            jp: [
                'サービスに関連するすべての知的財産権は、当社または正当な権利を有する第三者に帰属します。ユーザーは、サービスを利用してこれらの権利を侵害する行為を行ってはなりません。'
            ],
            my: [
                'Semua hak harta intelek yang berkaitan dengan Perkhidmatan adalah milik syarikat kami atau pihak ketiga yang mempunyai hak yang sah. Pengguna tidak boleh melakukan sebarang tindakan yang melanggar hak-hak ini dengan menggunakan Perkhidmatan.'
            ]
        }
    },
    {
        title: {
            en: 'Article 11 (Compensation for Damages)',
            cn: '第十一条（损害赔偿）',
            jp: '第11条（損害賠償）',
            my: 'Artikel 11 (Ganti Rugi)'
        },
        content: {
            en: [
                'If users violate these terms and conditions and cause damage to our company, users shall be responsible for compensating our company for such damage.',
            ],
            cn: [
                '如果用户违反这些条款和条件并对本公司造成损害，用户应负责赔偿本公司该等损害。'
            ],
            jp: [
                'ユーザーがこれらの利用規約に違反し、当社に損害を与えた場合、ユーザーは当社に対してその損害を賠償する責任を負います。'
            ],
            my: [
                'Jika pengguna melanggar syarat dan ketentuan ini dan menyebabkan kerosakan kepada syarikat kami, pengguna hendaklah bertanggungjawab untuk mengganti kerosakan tersebut kepada syarikat kami.'
            ]
        }
    },
    {
        title: {
            en: 'Article 12 (Revision of Terms and Conditions)',
            cn: '第十二条（条款和条件的修订）',
            jp: '第12条（利用規約の改訂）',
            my: 'Artikel 12 (Pindaan Syarat dan Ketentuan)'
        },
        content: {
            en: [
                'Our company may revise these terms and conditions without notifying users. The revised terms and conditions shall become effective from the date specified by our company.',
            ],
            cn: [
                '本公司可在未通知用户的情况下修订这些条款和条件。修订后的条款和条件自本公司指定的日期起生效。'
            ],
            jp: [
                '当社は、ユーザーに通知することなくこれらの利用規約を改訂することがあります。改訂後の利用規約は、当社が指定する日付から効力を有します。'
            ],
            my: [
                'Syarikat kami boleh meminda syarat dan ketentuan ini tanpa memberitahu pengguna. Syarat dan ketentuan yang dipinda akan berkuat kuasa mulai tarikh yang ditetapkan oleh syarikat kami.'
            ]
        }
    },
    {
        title: {
            en: 'Article 13 (Governing Law and Jurisdiction)',
            cn: '第十三条（适用法律和管辖权）',
            jp: '第13条（準拠法および管轄権）',
            my: 'Artikel 13 (Undang-Undang yang Mengawal dan Bidang Kuasa)'
        },
        content: {
            en: [
                'The interpretation of these terms and conditions shall be governed by the applicable law of the company\'s location. In the event of a dispute regarding the Service, the exclusive jurisdictional court shall be the court having jurisdiction over the company\'s location.',
            ],
            cn: [
                '这些条款和条件的解释应受公司所在地的适用法律管辖。在发生关于服务的争议时，具有公司所在地管辖权的法院应为专属管辖法院。'
            ],
            jp: [
                'これらの利用規約の解釈は、会社所在地の適用法に準拠するものとします。サービスに関する紛争が発生した場合、専属管轄裁判所は会社所在地の管轄を有する裁判所とします。'
            ],
            my: [
                'Tafsiran syarat dan ketentuan ini akan dikawal oleh undang-undang yang terpakai di lokasi syarikat. Sekiranya berlaku pertikaian mengenai Perkhidmatan, mahkamah bidang kuasa eksklusif akan menjadi mahkamah yang mempunyai bidang kuasa ke atas lokasi syarikat.'
            ]
        }
    },
    {
        title: {
            en: '[Supplementary Provisions]',
            cn: '[补充条款]',
            jp: '[補足規定]',
            my: '[Peruntukan Tambahan]'
        },
        content: {
            en: [
                'These terms and conditions shall come into effect on 1 October 2024.'
            ],
            cn: [
                '这些条款和条件自2024年10月1日起生效。'
            ],
            jp: [
                'これらの利用規約は2024年10月1日から効力を有します。'
            ],
            my: [
                'Syarat dan ketentuan ini akan berkuat kuasa mulai 1 Oktober 2024.'
            ]
        }
    }
];
