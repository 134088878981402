export const SignUpFormNames = {
    'username': 'username',
    'name': 'name',
    'phone': 'phone',
    'email': 'email',
    'password': 'password',
    'password_confirmation': 'password_confirmation',
    'dob': 'dob',
    'gender': 'gender',
    'nationality': 'nationality',
}

export const SignUpForm_Initial = {
    [SignUpFormNames.username]: '',
    [SignUpFormNames.name]: '',
    [SignUpFormNames.phone]: '',
    [SignUpFormNames.email]: '',
    [SignUpFormNames.password]: '',
    [SignUpFormNames.password_confirmation]: '',
    [SignUpFormNames.dob]: '',
    [SignUpFormNames.gender]: '',
    [SignUpFormNames.nationality]: '',
}

export const genderOptions = [
    { label: 'select_gender', value: '', isDisabled: true },
    { label: 'male', value: 'm' },
    { label: 'female', value: 'f' },
]

export const SignUpErrorKeys = {
    'short_username': 'short_username',
    'short_password': 'short_password',
    'password_not_match': 'password_not_match',
    'empty_fill': 'empty_fill',
    'invalid_email': 'invalid_email',
    'invalid_phone': 'invalid_phone',
    'gender_not_selected': 'gender_not_selected',
    'nationality_not_selected': 'nationality_not_selected',
}


export const SignUpErrorLists = {
    [SignUpErrorKeys.short_username]: 'Username should have at least 6 characters.',
    [SignUpErrorKeys.short_password]: 'Password should have at least 6 characters.',
    [SignUpErrorKeys.password_not_match]: 'Passwords not matched.',
    [SignUpErrorKeys.empty_fill]: 'This fill cannot be empty.',
    [SignUpErrorKeys.invalid_email]: 'Invalid email address.',
    [SignUpErrorKeys.invalid_phone]: 'Invalid mobile phone number.',
    [SignUpErrorKeys.gender_not_selected]: 'Please select your gender.',
    [SignUpErrorKeys.nationality_not_selected]: 'Please select your nationality.',
}