import { Autoplay, Navigation, Pagination } from 'swiper/modules';

export const swiperConfig = {
    breakpoints: {
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 25,
      },
      1280: {
        slidesPerView: 3,
        spaceBetween: 25,
      },
      1536: {
        slidesPerView: 3,
        spaceBetween: 25,
      }
    },
    navigation: false,
    loop: false,
    pagination: {
      clickable: true,
    },
    autoplay: {
      delay: 3500,
      disableOnInteraction: true,
    },
    modules: [Navigation, Pagination, Autoplay],
};