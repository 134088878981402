// src/pages/terms-and-conditions/terms-and-conditions.component.jsx

import React from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import SubHeader from "../../components/shared/sub-header/sub-header.component";
import { tncLists } from "../../data/terms-and-conditions";
import './terms-and-conditions.styles.scss';

const TermsAndConditions = () => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language || 'en'; // Default to 'en' if language is undefined

    return (
        <div className="tnc-container">
            <SubHeader>
                <div className="tnc-header-container">
                    <div className="main-header-title">{i18n.t('terms_and_conditions_header', { defaultValue: 'Terms & Conditions' })}</div>
                </div>
            </SubHeader>
            <div className="tnc-body-container">
                {
                    tncLists.map((tnc, index) => (
                        <div className="tnc-section" key={`tnc-section-${index}`}>
                            <div className="main-title">{tnc.title[currentLanguage]}</div>
                            <div className="tnc-content-container">
                                {
                                    tnc.content[currentLanguage].map((content, num) => (
                                        <div className="tnc-content" key={`tnc-content-${index}-${num}`}>{content}</div>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                }                    
            </div>
        </div>
    )
}

export default TermsAndConditions;
