import SubHeader from '../../components/shared/sub-header/sub-header.component';
import './manage-wedding.styles.scss';

const ManageWedding = () => {

    return (
        <div className="sign-up-body-container">
            <div className="sign-up-header-container">
                <SubHeader>
                    <div className="title-center">
                        <h1 className='main-header-title'>Manage Wedding</h1> 
                    </div>
                </SubHeader>
            </div>
        </div>
    )
}

export default ManageWedding