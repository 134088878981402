import HomeHero from '../../components/home-hero/home-hero.component';
import './home.styles.scss';
import HomePromotion from '../../components/home-promotion/home-promotion.component';
import HomeLatestNews from '../../components/home-latest-news/home-latest-news.component';
import HomeService from '../../components/home-service/home-service.component';
import Blog from '../../components/shared/blog/blog.component';

const Home = () => {
    return (
        <>
            <HomeHero />
            <HomePromotion />
            <div className="divider" />
            <HomeLatestNews />
            <div className="divider" />
            <HomeService />
            <Blog />
        </>
    )
}

export default Home