// src/components/shared/form-selection/form-selection.component.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import './form-selection.styles.scss';

const FormSelection = ({ label, id, name, options, onChange, errorMessage = null, placeholderKey }) => {
    const { t } = useTranslation();

    // Translate option labels
    const translatedOptions = options.map(option => ({
        ...option,
        label: t(option.label),
    }));

    // Translate placeholder if placeholderKey is provided
    const translatedPlaceholder = t(placeholderKey);

    const handleOnChange = (selectedOption) => {
        const { value } = selectedOption;
        const newData = { value, name };
        onChange(newData);
    };

    return (
        <div className="input-container">
            <label htmlFor={id}>{t(label)}</label>
            <Select
                className='form-selection-container'
                classNamePrefix="form-selection"
                defaultValue={translatedOptions[0]}
                isSearchable={false}
                options={translatedOptions} 
                name={name}
                placeholder={translatedPlaceholder}
                onChange={handleOnChange}
            />

            {
                errorMessage &&
                <small className="error-message">* {errorMessage}</small>
            }
        </div>
    )
}

export default FormSelection
