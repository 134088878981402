import './sub-header.styles.scss';

const SubHeader = ({children, imageBackground, backgroundColor}) => {

    imageBackground = '/images/banner/banner-03.png';

    return (
        <>
            <div className={`sub-header-container ${backgroundColor || ''}`} style={{backgroundImage: `url("${imageBackground}")` }} >
                <div className="body-container">
                    <div className="title-container">{children}</div>
                </div>
            </div>
        </>
    )
}

export default SubHeader