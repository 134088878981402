import React, { useState } from 'react';
import './sign-up-form-body.styles.scss';
import FormInput from '../shared/form-input/form-input.component';
import {
    genderOptions,
    SignUpErrorKeys,
    SignUpErrorLists,
    SignUpForm_Initial,
    SignUpFormNames,
} from '../../data/sign-up-form';
import FormPassword from '../shared/form-password/form-password.component';
import NationalitySelection from '../shared/nationality-selection/nationality-selection.component';
import FormSelection from '../shared/form-selection/form-selection.component';
import FormDatePicker from '../shared/form-date-picker/form-date-picker.component';
import { PathList } from '../../data/nav-link.data';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import DOMPurify from 'dompurify';
import validator from 'validator';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import axiosInstance from '../../api/axiosInstance'; // Import axiosInstance for API calls

const SignUpFormBody = () => {
    const { t } = useTranslation(); // Initialize translation
    const navigate = useNavigate(); // Initialize useNavigate for redirection

    const [errorMessage, setErrorMessage] = useState({});
    const [overallError, setOverallError] = useState('');
    const [tncCheckbox, setTncCheckbox] = useState(false);
    const [userForm, setUserForm] = useState(SignUpForm_Initial);
    const minChar = 6;

    // Handle form input changes
    const handleOnChange = (e) => {
        if (!e) return;

        let name, value;

        // Check if 'e' is an event or an object with 'name' and 'value'
        if (e.target) {
            // It's a standard event
            ({ name, value } = e.target);
        } else if (e.name && e.value) {
            // It's an object with 'name' and 'value'
            ({ name, value } = e);
        } else {
            console.error('Event structure is incorrect');
            return;
        }

        const purifiedName = typeof name === 'string' ? DOMPurify.sanitize(name.trim()) : name;

        let purifiedValue;

        if (typeof value === 'string') {
            purifiedValue = DOMPurify.sanitize(value.trim());
        } else if (value instanceof Date) {
            purifiedValue = value.toISOString(); // Convert Date to ISO string or your preferred format
        } else if (typeof value === 'boolean') {
            purifiedValue = value; // For checkbox values
        } else {
            console.warn(`Unhandled value type for field "${name}":`, value);
            purifiedValue = value;
        }

        if (!purifiedName) return;

        setUserForm((prevState) => ({
            ...prevState,
            [purifiedName]: purifiedValue,
        }));
    };

    // Clear all the form input
    const resetForm = () => {
        setUserForm(SignUpForm_Initial); // Reset form data
        setTncCheckbox(false); // Reset T&C checkbox
        setErrorMessage({});
        setOverallError('');
    };

    // Handle T&C checkbox
    const handleTnc = (e) => {
        const isChecked = e.target.checked;
        setTncCheckbox(isChecked);
    };

    // Validate and submit form to server
    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage({});
        setOverallError('');

        const {
            username,
            name, // Changed from 'fullname' to 'name'
            phone,
            email,
            password,
            password_confirmation,
            dob,
            gender,
            nationality,
        } = userForm;

        // Validation logic
        let errors = {};
        if (!validator.isEmail(email)) errors[SignUpFormNames.email] = SignUpErrorKeys.invalid_email;
        if (!validator.isMobilePhone(phone, 'any')) errors[SignUpFormNames.phone] = SignUpErrorKeys.invalid_phone;
        if (username.length < minChar) errors[SignUpFormNames.username] = SignUpErrorKeys.short_username;
        if (password.length < minChar) errors[SignUpFormNames.password] = SignUpErrorKeys.short_password;
        if (password !== password_confirmation) errors[SignUpFormNames.password_confirmation] = SignUpErrorKeys.password_not_match;

        Object.keys(userForm).forEach((field) => {
            if (!userForm[field]) {
                switch (field) {
                    case SignUpFormNames.gender:
                        errors[field] = SignUpErrorKeys.gender_not_selected;
                        break;
                    case SignUpFormNames.nationality:
                        errors[field] = SignUpErrorKeys.nationality_not_selected;
                        break;
                    default:
                        errors[field] = SignUpErrorKeys.empty_fill;
                        break;
                }
            }
        });

        if (!tncCheckbox) {
            setOverallError(t('agree_tnc_error'));
        }

        if (Object.keys(errors).length > 0 || !tncCheckbox) {
            setErrorMessage(errors);
            return;
        }

        const validatedFormData = {
            username,
            name, // Changed from 'fullname' to 'name'
            phone,
            email,
            password,
            password_confirmation,
            dob,
            gender,
            nationality,
        };

        // **Logging for debugging**
        console.log('Validated Form Data:', validatedFormData);
        Object.entries(validatedFormData).forEach(([key, value]) => {
            if (typeof value === 'object' && value !== null) {
                console.warn(`Field "${key}" contains an object:`, value);
            }
        });

        try {
            const response = await axiosInstance.post('/register', validatedFormData);
            console.log(response.data.message);
            resetForm();
            // Redirect to /login after successful registration
            navigate('/login'); // Redirect to the login page
        } catch (error) {
            console.error('Registration error:', error);
            if (error.response && error.response.status === 422 && error.response.data.errors) {
                const serverErrors = error.response.data.errors;
                const newErrorMessages = {};
                for (let key in serverErrors) {
                    newErrorMessages[key] = serverErrors[key][0];
                }
                setErrorMessage(newErrorMessages);
            } else {
                setOverallError(t('registration_failed'));
            }
        }
    };

    return (
        <div className="sign-up-form-body-container">
            <form className="form-box" onSubmit={handleSubmit} noValidate>
                <FormInput
                    id="name" // Changed from 'fullname' to 'name'
                    label={t('full_name')}
                    placeholder={t('enter_full_name')}
                    name={SignUpFormNames.name} // Changed from 'fullname' to 'name'
                    value={userForm.name} // Changed from 'fullname' to 'name'
                    onChange={handleOnChange}
                    errorMessage={
                        SignUpErrorLists[errorMessage.name] ? t(SignUpErrorLists[errorMessage.name]) : null
                    }
                    required
                />
                <FormInput
                    id="email"
                    label={t('email')}
                    placeholder={t('enter_email')}
                    name={SignUpFormNames.email}
                    type="email"
                    value={userForm.email}
                    onChange={handleOnChange}
                    errorMessage={
                        SignUpErrorLists[errorMessage.email] ? t(SignUpErrorLists[errorMessage.email]) : null
                    }
                    required
                />
                <FormInput
                    id="phone"
                    label={t('phone')}
                    placeholder={t('enter_phone')}
                    name={SignUpFormNames.phone}
                    type="tel"
                    value={userForm.phone}
                    onChange={handleOnChange}
                    errorMessage={
                        SignUpErrorLists[errorMessage.phone] ? t(SignUpErrorLists[errorMessage.phone]) : null
                    }
                    required
                />
                <FormDatePicker
                    id="dob"
                    label={t('date_of_birth')}
                    name={SignUpFormNames.dob}
                    value={userForm.dob}
                    onChange={handleOnChange}
                    errorMessage={
                        SignUpErrorLists[errorMessage.dob] ? t(SignUpErrorLists[errorMessage.dob]) : null
                    }
                    required
                />
                <FormSelection
                    label={t('gender')}
                    id="gender"
                    name={SignUpFormNames.gender}
                    options={genderOptions}
                    value={userForm.gender}
                    onChange={(option) => handleOnChange({ name: 'gender', value: option.value })}
                    errorMessage={
                        SignUpErrorLists[errorMessage.gender] ? t(SignUpErrorLists[errorMessage.gender]) : null
                    }
                    placeholderKey="select_gender"
                    required
                />
                <NationalitySelection
                    id="nationality"
                    label={t('nationality')}
                    name={SignUpFormNames.nationality}
                    value={userForm.nationality}
                    onChange={(option) => handleOnChange({ name: 'nationality', value: option.value })}
                    errorMessage={
                        SignUpErrorLists[errorMessage.nationality] ? t(SignUpErrorLists[errorMessage.nationality]) : null
                    }
                    required
                />
                <div className="separator" />
                <FormInput
                    id="username"
                    label={t('username')}
                    placeholder={t('enter_username')}
                    name={SignUpFormNames.username}
                    value={userForm.username}
                    onChange={handleOnChange}
                    errorMessage={
                        SignUpErrorLists[errorMessage.username] ? t(SignUpErrorLists[errorMessage.username]) : null
                    }
                    required
                />
                <FormPassword
                    id="password"
                    label={t('password')}
                    placeholder={t('enter_password')}
                    name={SignUpFormNames.password}
                    value={userForm.password}
                    onChange={handleOnChange}
                    errorMessage={
                        SignUpErrorLists[errorMessage.password] ? t(SignUpErrorLists[errorMessage.password]) : null
                    }
                    required
                />
                <FormPassword
                    id="password_confirmation"
                    label={t('confirm_password')}
                    placeholder={t('enter_confirm_password')}
                    name={SignUpFormNames.password_confirmation}
                    value={userForm.password_confirmation}
                    onChange={handleOnChange}
                    errorMessage={
                        SignUpErrorLists[errorMessage.password_confirmation]
                            ? t(SignUpErrorLists[errorMessage.password_confirmation])
                            : null
                    }
                    required
                />
                <div className="tnc-container">
                    <input
                        type="checkbox"
                        name="tnc"
                        id="tnc"
                        checked={tncCheckbox}
                        onChange={handleTnc}
                        required
                    />
                    <label htmlFor="tnc">
                        {t('i_accept_the')}{' '}
                        <Link
                            to={PathList.termAndCondition.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="link"
                        >
                            {t('terms_and_conditions')}
                        </Link>
                    </label>
                </div>
                {overallError && (
                    <div className="error-message-container">
                        <p className="error">* {overallError}</p>
                    </div>
                )}
                <div className="submit-button-container">
                    <button
                        type="submit"
                        className={`submit-button ${tncCheckbox ? '' : 'disabled'}`}
                        disabled={!tncCheckbox}
                    >
                        {t('sign_up_button')}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default SignUpFormBody;
