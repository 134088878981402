// config/i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Translation resources
const resources = {
  en: {
    translation: {
      "menu": "Menu",
      "welcome": "Welcome",
      "company_name": "Wed Pro",
      "follow_us_on": "Follow us on",
      "contact_email": "wedpro0214@gmail.com",
      "home": "Home",
      "my_wedding": "My Wedding",
      "login": "Login",
      "dont_have_account": "Don't have an account?",
      "sign_up": "Sign up",
      "create_account": "Create account",
      "full_name": "Full name",
      "enter_full_name": "Enter your full name",
      "email": "Email",
      "enter_email": "Enter your email",
      "phone": "Phone",
      "enter_phone": "Example: +6010 0000000",
      "date_of_birth": "Date of Birth",
      "gender": "Gender",
      "select_gender": "Select your gender",
      "nationality": "Nationality",
      "select_nationality": "Select your nationality",
      "username": "Username",
      "enter_username": "Enter your username",
      "password": "Password",
      "enter_password": "Enter your password",
      "confirm_password": "Confirm Password",
      "enter_confirm_password": "Confirm your password",
      "terms_and_conditions": "Terms and Conditions",
      "sign_up_button": "Sign up",
      "invalid_email": "Invalid email address",
      "invalid_phone": "Invalid phone number",
      "short_username": "Username must be at least 6 characters long",
      "short_password": "Password must be at least 6 characters long",
      "password_not_match": "Passwords do not match",
      "gender_not_selected": "Please select your gender",
      "nationality_not_selected": "Please select your nationality",
      "empty_fill": "This field is required",
      "registration_failed": "Failed to create account, please try again.",
      "agree_tnc_error": "You must agree with the terms and conditions.",
      "rings": "Rings",
      "wedding_dress": "Wedding Dress",
      "flowers": "Flowers",
      "cakes": "Cakes",
      "gifts": "Gifts",
      "hotel": "Hotel",
      "honeymoon": "Honeymoon Travel",
      "latest_news": "Latest News",
      "your_perfect_day": "Your perfect day, our perfect plan.",
      "plan_your_wedding": "Plan your wedding now",
      "language": "Language",
      "news_title_1": "Exciting New Wedding Trends for 2024",
      "news_title_2": "Top 10 Honeymoon Destinations",
      "promotions": "Promotions",
      "promotion_title_1": "Summer Sale",
      "promotion_desc_1": "Get 20% off on all items",
      "services": "Services",
      "read_more": "Read more",
      "blogs": "Blogs",
      "my_wedding_word_1": "MY",
      "my_wedding_word_2": "WEDDING",
      "plan": "PLAN",
      "select_events": "Select the event(s) that you want:",
      "no_plan_yet": "No plan yet.",
      "back": "Back",
      "next": "Next",
      "proceed": "Proceed",
      "footer_quote_line1": "Together is a",
      "footer_quote_line2": "beautiful place to be.",
      "login_with": "Login with",
      "google": "Google",
      "facebook": "Facebook",
      "loading": "Loading...",
      "male": "Male",
      "female": "Female",
      "email_or_username": "Email / Username",
      "enter_username_or_email": "Enter your username or email",
      "remember_me": "Remember Me",
      "forgot_password": "Forgot Password",
      "email_label": "Email Address",
      "enter_email_placeholder": "Enter your email",
      "invalid_email_error": "Please enter a valid email address.",
      "reset_button": "Reset Password",
      "temporary_password_info": "If an account with the provided email exists, you will receive a temporary password.",
      "not_receiving_mail": "Not receiving the email?",
      "send_again": "Send again",
      "back_to_login": "Back to Login",
      "reset_password": "Reset Password",
      "something_went_wrong": "Something went wrong. Please try again later.",
      "email_not_found": "User has not yet registered. Redirecting to login page...",
      "your_dream_wedding": "Your Dream Wedding Begins Here.",
      "plan_now": "Plan now",
      "i_accept_the": "I accept the",
    },
  },
  cn: {
    translation: {
      "menu": "菜单",
      "welcome": "欢迎",
      "company_name": "Wed Pro",
      "follow_us_on": "关注我们",
      "contact_email": "wedpro0214@gmail.com",
      "home": "主页",
      "my_wedding": "我的婚礼",
      "login": "登录",
      "dont_have_account": "还没有账号？",
      "sign_up": "注册",
      "create_account": "创建账户",
      "full_name": "全名",
      "enter_full_name": "请输入您的全名",
      "email": "电子邮件",
      "enter_email": "请输入您的电子邮件",
      "phone": "电话",
      "enter_phone": "示例: +6010 0000000",
      "date_of_birth": "出生日期",
      "gender": "性别",
      "select_gender": "请选择您的性别",
      "nationality": "国籍",
      "select_nationality": "请选择您的国籍",
      "username": "用户名",
      "enter_username": "请输入您的用户名",
      "password": "密码",
      "enter_password": "请输入您的密码",
      "confirm_password": "确认密码",
      "enter_confirm_password": "确认您的密码",
      "terms_and_conditions": "条款和条件",
      "sign_up_button": "注册",
      "invalid_email": "无效的电子邮件地址",
      "invalid_phone": "无效的电话号码",
      "short_username": "用户名至少需要6个字符",
      "short_password": "密码至少需要6个字符",
      "password_not_match": "密码不匹配",
      "gender_not_selected": "请选择您的性别",
      "nationality_not_selected": "请选择您的国籍",
      "empty_fill": "此字段为必填项",
      "registration_failed": "创建账户失败，请再试一次。",
      "agree_tnc_error": "您必须同意条款和条件。",
      "rings": "戒指",
      "wedding_dress": "婚纱",
      "flowers": "花束",
      "cakes": "蛋糕",
      "gifts": "礼物",
      "hotel": "酒店",
      "honeymoon": "蜜月旅行",
      "latest_news": "最新消息",
      "your_perfect_day": "您的完美一天，我们的完美计划。",
      "plan_your_wedding": "立即计划您的婚礼",
      "language": "语言",
      "news_title_1": "2024年令人兴奋的新婚礼趋势",
      "news_title_2": "十大蜜月目的地",
      "promotions": "促销",
      "promotion_title_1": "夏季大减价",
      "promotion_desc_1": "所有商品享受 20% 折扣",
      "services": "服务",
      "read_more": "阅读更多",
      "blogs": "博客",
      "my_wedding_word_1": "我的",
      "my_wedding_word_2": "婚礼",
      "plan": "计划",
      "select_events": "选择您想要的活动：",
      "no_plan_yet": "还没有计划。",
      "back": "返回",
      "next": "下一步",
      "proceed": "继续",
      "footer_quote_line1": "在一起是一个",
      "footer_quote_line2": "美丽的地方。",
      "login_with": "使用以下方式登录",
      "google": "谷歌",
      "facebook": "脸书",
      "loading": "加载中...",
      "male": "男",
      "female": "女",
      "email_or_username": "电子邮件/用户名",
      "enter_username_or_email": "请输入您的用户名或电子邮件",
      "remember_me": "记住我",
      "forgot_password": "忘记密码",
      "reset_button": "重置密码",
      "temporary_password_info": "我们将向您发送一个临时新密码。",
      "not_receiving_mail": "没有收到邮件？",
      "send_again": "再次发送",
      "back_to_login": "返回登录",
      "something_went_wrong": "出了点问题，请稍后再试。",
      "email_not_found": "用户尚未注册。正在重定向到登录页面...",
      "your_dream_wedding": "你的梦幻婚礼从这里开始。",
      "plan_now": "立即计划",
      "i_accept_the": "我接受",
    },
  },
  jp: {
    translation: {
      "menu": "メニュー",
      "welcome": "ようこそ",
      "company_name": "ウェッドプロ",
      "follow_us_on": "フォローする",
      "contact_email": "wedpro0214@gmail.com",
      "home": "ホーム",
      "my_wedding": "私の結婚式",
      "login": "ログイン",
      "dont_have_account": "アカウントをお持ちでないですか？",
      "sign_up": "サインアップ",
      "create_account": "アカウントを作成",
      "full_name": "フルネーム",
      "enter_full_name": "フルネームを入力してください",
      "email": "メール",
      "enter_email": "メールを入力してください",
      "phone": "電話番号",
      "enter_phone": "例: +6010 0000000",
      "date_of_birth": "生年月日",
      "gender": "性別",
      "select_gender": "性別を選択してください",
      "nationality": "国籍",
      "select_nationality": "国籍を選択してください",
      "username": "ユーザー名",
      "enter_username": "ユーザー名を入力してください",
      "password": "パスワード",
      "enter_password": "パスワードを入力してください",
      "confirm_password": "パスワードの確認",
      "enter_confirm_password": "パスワードを確認してください",
      "terms_and_conditions": "利用規約",
      "sign_up_button": "サインアップ",
      "invalid_email": "無効なメールアドレス",
      "invalid_phone": "無効な電話番号",
      "short_username": "ユーザー名は6文字以上でなければなりません",
      "short_password": "パスワードは6文字以上でなければなりません",
      "password_not_match": "パスワードが一致しません",
      "gender_not_selected": "性別を選択してください",
      "nationality_not_selected": "国籍を選択してください",
      "empty_fill": "このフィールドは必須です",
      "registration_failed": "アカウントの作成に失敗しました。もう一度お試しください。",
      "agree_tnc_error": "利用規約に同意する必要があります。",
      "rings": "指輪",
      "wedding_dress": "ウェディングドレス",
      "flowers": "花",
      "cakes": "ケーキ",
      "gifts": "ギフト",
      "hotel": "ホテル",
      "honeymoon": "新婚旅行",
      "latest_news": "最新ニュース",
      "your_perfect_day": "完璧な1日を、完璧なプランで。",
      "plan_your_wedding": "今すぐ結婚式を計画する",
      "language": "言語",
      "news_title_1": "2024年のエキサイティングな新しい結婚式のトレンド",
      "news_title_2": "トップ10の新婚旅行先",
      "promotions": "プロモーション",
      "promotion_title_1": "サマーセール",
      "promotion_desc_1": "全商品20%オフ",
      "services": "サービス",
      "read_more": "続きを読む",
      "blogs": "ブログ",
      "my_wedding_word_1": "MY",
      "my_wedding_word_2": "WEDDING",
      "plan": "PLAN",
      "select_events": "希望するイベントを選択してください：",
      "no_plan_yet": "まだ計画はありません。",
      "back": "戻る",
      "next": "次へ",
      "proceed": "続ける",
      "footer_quote_line1": "Together is a",
      "footer_quote_line2": "beautiful place to be.",
      "login_with": "以下でログイン",
      "google": "グーグル",
      "facebook": "フェイスブック",
      "loading": "読み込み中...",
      "male": "男性",
      "female": "女性",
      "email_or_username": "メールまたはユーザー名",
      "enter_username_or_email": "ユーザー名またはメールアドレスを入力してください",
      "remember_me": "ログイン状態を保持する",
      "forgot_password": "パスワードをお忘れですか？",
      "reset_button": "リセット",
      "temporary_password_info": "一時的な新しいパスワードをお送りします。",
      "not_receiving_mail": "メールが届いていませんか？",
      "send_again": "再送信",
      "back_to_login": "ログインに戻る",
      "something_went_wrong": "問題が発生しました。後でもう一度お試しください。",
      "email_not_found": "ユーザーはまだ登録されていません。ログインページにリダイレクトしています...",
      "your_dream_wedding": "あなたの夢の結婚式はここから始まります。",
      "plan_now": "今すぐ計画する",
      "i_accept_the": "私は同意します",
    },
  },
  my: {
    translation: {
      "menu": "Menu",
      "welcome": "Selamat Datang",
      "company_name": "Wed Pro",
      "follow_us_on": "Ikuti kami di",
      "contact_email": "wedpro0214@gmail.com",
      "home": "Laman Utama",
      "my_wedding": "Perkahwinan Saya",
      "login": "Log Masuk",
      "dont_have_account": "Belum memiliki akun?",
      "sign_up": "Daftar",
      "create_account": "Buat akaun",
      "full_name": "Nama Penuh",
      "enter_full_name": "Masukkan nama penuh anda",
      "email": "Emel",
      "enter_email": "Masukkan emel anda",
      "phone": "Telefon",
      "enter_phone": "Contoh: +6010 0000000",
      "date_of_birth": "Tarikh Lahir",
      "gender": "Jantina",
      "select_gender": "Pilih jantina anda",
      "nationality": "Kebangsaan",
      "select_nationality": "Pilih kebangsaan anda",
      "username": "Nama Pengguna",
      "enter_username": "Masukkan nama pengguna anda",
      "password": "Kata Laluan",
      "enter_password": "Masukkan kata laluan anda",
      "confirm_password": "Sahkan Kata Laluan",
      "enter_confirm_password": "Sahkan kata laluan anda",
      "terms_and_conditions": "Terma dan Syarat",
      "sign_up_button": "Daftar",
      "invalid_email": "Alamat emel tidak sah",
      "invalid_phone": "Nombor telefon tidak sah",
      "short_username": "Nama pengguna mesti sekurang-kurangnya 6 aksara",
      "short_password": "Kata laluan mesti sekurang-kurangnya 6 aksara",
      "password_not_match": "Kata laluan tidak sepadan",
      "gender_not_selected": "Sila pilih jantina anda",
      "nationality_not_selected": "Sila pilih kebangsaan anda",
      "empty_fill": "Medan ini diperlukan",
      "registration_failed": "Gagal mencipta akaun, sila cuba lagi.",
      "agree_tnc_error": "Anda mesti bersetuju dengan terma dan syarat.",
      "rings": "Cincin",
      "wedding_dress": "Gaun Pengantin",
      "flowers": "Bunga",
      "cakes": "Kek",
      "gifts": "Hadiah",
      "hotel": "Hotel",
      "honeymoon": "Perjalanan Bulan Madu",
      "latest_news": "Berita Terkini",
      "your_perfect_day": "Hari sempurna anda, rancangan sempurna kami.",
      "plan_your_wedding": "Rancang perkahwinan anda sekarang",
      "language": "Bahasa",
      "news_title_1": "Trend Perkahwinan Baru yang Menarik untuk 2024",
      "news_title_2": "10 Destinasi Bulan Madu Terbaik",
      "promotions": "Promosi",
      "promotion_title_1": "Jualan Musim Panas",
      "promotion_desc_1": "Dapatkan diskaun 20% untuk semua barangan",
      "services": "Perkhidmatan",
      "read_more": "Baca lagi",
      "blogs": "Blog",
      "my_wedding_word_1": "PERKAHWINAN",
      "my_wedding_word_2": "SAYA",
      "plan": "RANCANGAN",
      "select_events": "Pilih acara yang anda inginkan:",
      "no_plan_yet": "Belum ada rancangan.",
      "back": "Kembali",
      "next": "Seterusnya",
      "proceed": "Teruskan",
      "footer_quote_line1": "Bersama adalah",
      "footer_quote_line2": "tempat yang indah untuk berada.",
      "login_with": "Log masuk dengan",
      "google": "Google",
      "facebook": "Facebook",
      "loading": "Memuat...",
      "male": "Lelaki",
      "female": "Perempuan",
      "email_or_username": "Emel / Nama Pengguna",
      "enter_username_or_email": "Masukkan nama pengguna atau emel anda",
      "remember_me": "Ingat Saya",
      "forgot_password": "Lupa Kata Laluan",
      "reset_button": "Tetapkan Semula Kata Laluan",
      "temporary_password_info": "Kami akan menghantar kata laluan baru sementara kepada anda.",
      "not_receiving_mail": "Tidak menerima e-mel?",
      "send_again": "Hantar semula",
      "back_to_login": "Kembali ke Log Masuk",
      "something_went_wrong": "Ada masalah berlaku. Sila cuba lagi nanti.",
      "email_not_found": "Pengguna belum mendaftar. Mengalihkan ke halaman log masuk...",
      "your_dream_wedding": "Perkahwinan Impian Anda Bermula Di Sini.",
      "plan_now": "Rancang Sekarang",
      "i_accept_the": "Saya menerima",
    },
  },
};

// Initialize i18next
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
