import { Link } from 'react-router-dom';
import './nav-request-login.styles.scss';
import { useTranslation } from 'react-i18next';
import { PathList } from '../../data/nav-link.data';

const NavRequestLogin = ({setToggle}) => {
    const { t } = useTranslation();
    return (
        <Link to={PathList.login.pathname} className='nav-request-login-container' onClick={setToggle}>
            <div className="login-button">
                <span className="material-icons-outlined">login</span>
                <p> {t('login')}</p>
            </div>
        </Link>
    )
}

export default NavRequestLogin