import './forget-password-form.styles.scss';
import React, { useState, useEffect } from 'react';
import FormInput from '../../components/shared/form-input/form-input.component';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import isEmail from 'validator/lib/isEmail';
import { Link, useNavigate } from 'react-router-dom';
import { PathList } from '../../data/nav-link.data';
import axiosInstance from '../../api/axiosInstance';

const ForgetPasswordForm = () => {
    const { t } = useTranslation();
    const navigate = useNavigate(); // Hook to handle navigation
    const [errorMessage, setErrorMessage] = useState(null);
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [countdown, setCountdown] = useState(0);

    // Handle input change
    const handleOnChange = (e) => {
        const purifiedEmail = DOMPurify.sanitize(e.target.value.trim());
        setEmail(purifiedEmail);
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage(null);

        if (!isEmail(email)) {
            setErrorMessage(t('invalid_email_error'));
            return;
        }

        try {
            // Send request to backend
            const response = await axiosInstance.post('/forgot-password', { email });
            if (response.status === 200) {
                setSubmitted(true);
                setCountdown(300); // Set countdown to 5 minutes (300 seconds)
            } else {
                setErrorMessage(t('something_went_wrong'));
            }
        } catch (error) {
            console.error('Error submitting forget password:', error);
            if (error.response) {
                if (error.response.status === 404) {
                    setErrorMessage(t('email_not_found'));
                    // Redirect to login page after a brief delay
                    setTimeout(() => {
                        navigate(PathList.login.url);
                    }, 2000);
                } else if (error.response.data && error.response.data.message) {
                    setErrorMessage(error.response.data.message);
                } else {
                    setErrorMessage(t('something_went_wrong'));
                }
            } else {
                setErrorMessage(t('something_went_wrong'));
            }
        }
    };

    // Handle resend reset email
    const handleResend = async () => {
        try {
            console.log('Resend password reset email');
            const response = await axiosInstance.post('/forgot-password', { email });
            if (response.status === 200) {
                setCountdown(300); // Reset countdown to 5 minutes after resend
            } else {
                setErrorMessage(t('something_went_wrong'));
            }
        } catch (error) {
            console.error('Error resending reset email:', error);
            setErrorMessage(t('something_went_wrong'));
        }
    };

    // Countdown timer effect
    useEffect(() => {
        let timer;
        if (countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        }
        return () => clearInterval(timer); // Cleanup the timer on component unmount
    }, [countdown]);

    // Format countdown to MM:SS
    const formatCountdown = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    return (
        <>
            {
                !submitted ? (
                    <form onSubmit={handleSubmit} className="forget-password-form">
                        <FormInput 
                            id="email"
                            label={t('email_label')}
                            name="email"
                            placeholder={t('enter_email_placeholder')}
                            errorMessage={errorMessage}
                            onChange={handleOnChange}
                            value={email}
                        />
                        <div className="submit-button-container">
                            <button type="submit">{t('reset_button')}</button>
                        </div> 
                    </form>
                ) : (
                    <div className="forget-password-info-container">
                        <div className="text-container">
                            <h6>{t('temporary_password_info')}</h6>
                            <small>
                                {t('not_receiving_mail')} 
                                {
                                    countdown > 0 ? (
                                        <span className="countdown">{` (${formatCountdown(countdown)})`}</span>
                                    ) : (
                                        <span className="resend" onClick={handleResend}>{t('send_again')}</span>
                                    )
                                }
                            </small>
                        </div>
                        <Link to={PathList.login.url} className="link-button">{t('back_to_login')}</Link>
                    </div>
                )
            }
        </>
    );
};

export default ForgetPasswordForm;
