import React from 'react';
import SignUpFormBody from '../sign-up-form-body/sign-up-form-body.component';
import './sign-up-form.styles.scss';
import { useTranslation } from 'react-i18next';

const SignUpForm = () => {
    const { t } = useTranslation();

    return (
        <div className="sign-up-form-container">

            <div className="form-container">
                <div className="form-card">
                    <h2 className="title">{t('create_account')}</h2> 
                    <SignUpFormBody />
                </div>
            </div>

        </div>
    )
}

export default SignUpForm
