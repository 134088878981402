// Corrected setNavActive.js
/**
 * Check current path and set active to the link on navbar
 *
 * @param {string} currentPath
 * @param {string} url
 * @returns {string}
 */
export const setNavActive = (currentPath, url) => {    
    if (currentPath !== url) return;
    return 'active';    
}
