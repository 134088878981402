import './form-input.styles.scss';

const FormInput = ({ label, name, placeholder, id, onChange, type = 'text', value = '', errorMessage = null }) => {
    return (
        <div className="input-form-container">
            <label htmlFor={id}>{label}</label>
            <input 
                id={id}
                className='input' 
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={onChange || null}
                type={type}
                required
            />
            {
                errorMessage &&
                <small className="error-message">* {errorMessage}</small>
            }
        </div>
    );
}

export default FormInput;
