// src/store/store.js

import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { thunk } from 'redux-thunk'; // Correct named import
import storage from 'redux-persist/lib/storage';
import rootReducer from './root-reducer.js'; // Adjust the path as necessary

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'questionnaire'], // Persist the 'auth' and 'questionnaire' reducers
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [thunk]; // Apply redux-thunk middleware

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

export const persistor = persistStore(store);
