// components/home-hero/home-hero.component.js

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './home-hero.styles.scss';
import { PathList } from '../../data/nav-link.data';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const HomeHero = () => {
  const { t, i18n } = useTranslation();
  const userDetails = useSelector((state) => state.auth.user);

  useEffect(() => {}, [i18n.language]);

  // Determine the destination URL based on whether the user has completed the questionnaire
  const destinationUrl =
    userDetails && userDetails.fill_questionnaire === 1
      ? PathList.manageWedding.url
      : PathList.myWedding.url;

  return (
    <div className="hero-banner-container">
      <span className="overlay"></span>
      <div className="body-container">
        <div className="title-container">
          <h1 className="title">{t('your_dream_wedding')}</h1>
          <Link to={destinationUrl}>
            <div className="link-button">{t('plan_now')}</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomeHero;
