// nav-link.data.js
export const PathList = {
    home: { title: 'home', url: '/', pathname: '' },
    myWedding: { title: 'my_wedding', url: '/questionnaire', pathname: 'questionnaire' },
    login: { title: 'login', url: '/login', pathname: 'login' },
    signup: { title: 'sign_up', url: '/sign-up', pathname: 'sign-up' },
    termAndCondition: { title: 'terms_and_conditions', url: '/terms-and-conditions', pathname: 'terms-and-conditions' },
    forgetPassword: { title: 'forget_password', url: '/forget-password', pathname: 'forget-password' },
    manageWedding: { title: 'manage_wedding', url: '/manage-wedding', pathname: 'manage-wedding' },
  };
  
  export const NavLinks = [
    { title: PathList.home.title, url: PathList.home.url, pathname: PathList.home.pathname },
    { title: PathList.myWedding.title, url: PathList.myWedding.url, pathname: PathList.myWedding.pathname },
  ];
  