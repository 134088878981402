import './form-password.styles.scss';
import { Eye, EyeOff } from "lucide-react";
import { useState } from 'react';

const FormPassword = ({ label, id, placeholder, name, onChange, value = '', errorMessage = null }) => {
    const [showPassword, setShowPassword] = useState(false);

    // toggle show password
    const togglePasswordVisibility = () => setShowPassword(!showPassword);

    return (
        <div className="input-password-container">
            <label htmlFor={id}>{label}</label>
            <div className="input">
                <input
                    id={id}
                    className='password'
                    type={showPassword ? "text" : "password"}
                    placeholder={placeholder}
                    name={name}
                    value={value}
                    onChange={onChange || null}
                    required
                />
                {/* show password button */}
                <button
                    type="button"
                    onClick={togglePasswordVisibility}
                >
                    {
                        showPassword ? (
                            <EyeOff className="icon" />
                        ) : (
                            <Eye className="icon" />
                    )}
                </button>
            </div>
            {
                errorMessage &&
                <small className="error-message">* {errorMessage}</small>
            }
        </div>
    );
}

export default FormPassword;
