import './nationality-selection.styles.scss';

import React, { useMemo } from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { useTranslation } from 'react-i18next';

const NationalitySelection = ({ id, label, name, onChange, errorMessage = null }) => {
    const { t } = useTranslation();
    const options = useMemo(() => countryList().getData(), []);

    const handleOnChange = (option) => {
        const { label } = option;
        const newData = { value: label, name };
        onChange(newData);
    };

    return (
        <div className="input-container">
            <label htmlFor={id}>{t(label)}</label>
            <Select
                className='nationality-container'
                classNamePrefix="ns"
                options={options}
                name={name}
                onChange={handleOnChange}
                placeholder={t('select_nationality')} 
            />
            {
                errorMessage &&
                <small className="error-message">* {errorMessage}</small>
            }
        </div>
    );
};

export default NationalitySelection;
