export const SET_QUESTIONNAIRE_OPTIONS = 'SET_QUESTIONNAIRE_OPTIONS';
export const SET_THEME_CATEGORY = 'SET_THEME_CATEGORY';
export const SET_EVENT_LIST = 'SET_EVENT_LIST';
export const SET_SELECTED_THEME = 'SET_SELECTED_THEME';

export const setSelectedTheme = (themeId) => ({
  type: SET_SELECTED_THEME,
  payload: themeId,
});

export const setThemeCategory = (themeId) => ({
  type: SET_THEME_CATEGORY,
  payload: themeId,
});

export const setQuestionnaireOptions = (phase, options) => ({
  type: SET_QUESTIONNAIRE_OPTIONS,
  payload: { phase, options },
});

export const setEventList = (eventList) => ({
  type: SET_EVENT_LIST,
  payload: eventList,
});

export const appendEventList = (newEvents) => ({
  type: 'APPEND_EVENT_LIST',
  payload: newEvents,
});
