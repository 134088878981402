import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LayoutMain from '../components/shared/layout-main/layout-main.component';
import ProtectedRoute from '../components/shared/protected-route/protected-route.component';
import Home from '../pages/home/home.page';
import NotFound from '../pages/not-found/not-found.page';
import Login from '../pages/login/login.page';
import SignUp from '../pages/sign-up/sign-up.page';
import ForgetPassword from '../pages/forget-password/forget-password.page';
import TermsAndConditions from '../pages/terms-and-conditions/terms-and-conditions.page';
import { PathList } from '../data/nav-link.data';
import ManageWedding from '../pages/manage-wedding/manage-wedding.page';
import {lazy, Suspense} from 'react';
import DefaultLoading from '../components/shared/default-loading/default-loading.component';
// import Questionnaire from '../pages/questionnaire/questionnaire.page';

const Questionnaire = lazy(()=> import('../pages/questionnaire/questionnaire.page'));

const AppRoutes = () => {
  return (
    <Suspense fallback={<DefaultLoading/>}>
      <Routes>
        <Route path="/" element={<LayoutMain />}>
          <Route index element={<Home />} />
          <Route
            path={PathList.myWedding.pathname}
            element={
              <ProtectedRoute>
                <Questionnaire />
              </ProtectedRoute>
            }
          />
          <Route
            path={PathList.myWedding.pathname + "/:page"}
            element={
              <ProtectedRoute>
                <Questionnaire />
              </ProtectedRoute>
            }
          />
          {/* <Route path="wedding-planning" element={<WeddingPlanningPage />} /> */}
          <Route path={PathList.login.pathname} element={<Login />} />
          <Route path={PathList.signup.pathname} element={<SignUp />} />
          <Route path={PathList.forgetPassword.pathname} element={<ForgetPassword />} />
          <Route path={PathList.termAndCondition.pathname} element={<TermsAndConditions />} />
          <Route
            path={PathList.manageWedding.pathname}
            element={
              <ProtectedRoute>
                <ManageWedding />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
