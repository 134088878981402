import { AnimatePresence, motion } from 'framer-motion';
import './dropdown-container.styles.scss';
import { dropdownFade } from '../../../libs/animation/dropdown';
import { useEffect, useRef } from 'react';

const DropdownContainer = ({children, classes, isToggle=false, setToggle}) => {

    const dropdownRef = useRef(null);

    useEffect(() => {        
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setToggle(false);  
            }
        };

        if (isToggle) {
            window.addEventListener('click', handleClickOutside);
        } else {
            window.removeEventListener('click', handleClickOutside);
        }

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [isToggle, setToggle]);

    return (
        <>
            <AnimatePresence>
                {isToggle && (
                    <motion.div 
                        ref={dropdownRef}
                        className={`dropdown-container ${classes || ''}`}
                        variants={dropdownFade}
                        initial='start'
                        animate='end'
                        exit='exit'
                        transition={dropdownFade.transition}
                    >
                        {children}
                    </motion.div>
                )}  
            </AnimatePresence>
        </>
    )
}

export default DropdownContainer