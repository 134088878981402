import LoginForm from "../../components/login-form/login-form.component";
import SubHeader from "../../components/shared/sub-header/sub-header.component";
import './login.styles.scss';
import { useTranslation } from 'react-i18next';

const Login = () => {
    const { t } = useTranslation(); 

    return (
        <>
            <div className="login-body-container">
                <div className="login-header-container">
                    <SubHeader>
                        <div className="title-center">
                            <h1 className='main-header-title'>{t('login')}</h1> 
                        </div>
                    </SubHeader>
                </div>
                <LoginForm />                
            </div>
        </>
    )
}

export default Login