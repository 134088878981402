import { Link } from 'react-router-dom';
import './footer.styles.scss';
import { socialMediaList } from '../../../data/social-media';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="footer-container">
      <div className="card-container">
        <div className="bg-logo">
          <img src="/images/logo/theme-white.svg" alt="" />
        </div>
        <div className="upper-body-container">
          <div className="footer-quote">
            <h1>{t('footer_quote_line1')}</h1>
            <h1>{t('footer_quote_line2')}</h1>
          </div>  
          <div className="social-media-container">
            {
              socialMediaList.map(media=>(
                <Link className="media-button" target='_blank' to={media.href} key={media.title}>
                  <div className="logo">
                    <img src={media.imageUrl} alt={media.title} />
                  </div>
                </Link>
              ))
            }
          </div>
        </div>      
        <div className="bottom-footer">
          <div className="bottom-body-container">
            <div className="contact-container">
              <p className="email">{t('contact_email')}</p>
            </div>
          </div>
        </div>
      </div>            
    </div>
  );
};

export default Footer;
