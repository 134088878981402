import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import './home-promotion.styles.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Link } from 'react-router-dom';
import { swiperConfig } from '../../libs/config/swiper';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../api/axiosInstance';  // Assuming axiosInstance is set up

const HomePromotion = () => {
  const { t, i18n } = useTranslation();
  const [promotionsList, setPromotionsList] = useState([]);

  useEffect(() => {
    const fetchPromotions = async () => {
      try {
        const response = await axiosInstance.get('/promotions');
        setPromotionsList(response.data);
      } catch (error) {
        console.error('Error fetching promotions:', error);
      }
    };
  
    fetchPromotions();
  }, []);
  

  // Helper function to get the appropriate promotion title based on the current language
  const getPromotionTitle = (promotion) => {
    const currentLanguage = i18n.language;
    if (currentLanguage === 'en') {
      return promotion.translation.name_en;
    } else if (currentLanguage === 'cn') {
      return promotion.translation.name_cn;
    } else if (currentLanguage === 'jp') {
      return promotion.translation.name_jp;
    } else if (currentLanguage === 'my') {
      return promotion.translation.name_my;
    } else {
      return promotion.translation.name_en;  // Fallback to English
    }
  };

  // Helper function to get the appropriate promotion description based on the current language
  const getPromotionDescription = (promotion) => {
    const currentLanguage = i18n.language;
    if (currentLanguage === 'en') {
      return promotion.translation.description_en;
    } else if (currentLanguage === 'cn') {
      return promotion.translation.description_cn;
    } else if (currentLanguage === 'jp') {
      return promotion.translation.description_jp;
    } else if (currentLanguage === 'my') {
      return promotion.translation.description_my;
    } else {
      return promotion.translation.description_en;  // Fallback to English
    }
  };

  return (
    <div className="home-ads-container">
      <div className="body-container">
        <h1 className="main-title">{t('promotions')}</h1>
        <div className="swiper-container">
          <Swiper {...swiperConfig} className="swiper-card">
            {promotionsList.map((promotion) => (
              <SwiperSlide key={promotion.id}>
                <Link className="card" to={`/promotions/${promotion.id}`}>
                  <span
                    className="image"
                    style={{
                      backgroundImage: `url("${promotion.imageUrl || '/images/default/promotion.png'}")`,
                    }}
                  />
                  <div className="text-container">
                    <div className="title">{getPromotionTitle(promotion)}</div>
                    <div className="description">{getPromotionDescription(promotion)}</div>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default HomePromotion;
