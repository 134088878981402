import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './blog.styles.scss';
import 'material-icons/iconfont/filled.css';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../../api/axiosInstance';  // Assuming axiosInstance is set up

const Blog = () => {
    const { t, i18n } = useTranslation();  // i18n to detect current language
    const [blogs, setBlogs] = useState([]);

    // Fetch blog posts from the API when the component mounts
    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axiosInstance.get('/blog-posts');
                setBlogs(response.data);  // Store the blog posts in state
            } catch (error) {
                console.error('Error fetching blog posts:', error);
            }
        };

        fetchBlogs();
    }, []);  // Empty dependency array ensures this runs only once when the component mounts

    // Helper function to get the appropriate blog title based on the current language
    const getBlogTitle = (blog) => {
        const currentLanguage = i18n.language;
        if (currentLanguage === 'en') {
            return blog.translation.name_en;
        } else if (currentLanguage === 'cn') {
            return blog.translation.name_cn;
        } else if (currentLanguage === 'jp') {
            return blog.translation.name_jp;
        } else if (currentLanguage === 'my') {
            return blog.translation.name_my;
        } else {
            return blog.translation.name_en;  // Fallback to English if language is not supported
        }
    };

    // Helper function to get the appropriate blog description based on the current language
    const getBlogDescription = (blog) => {
        const currentLanguage = i18n.language;
        if (currentLanguage === 'en') {
            return blog.translation.description_en;
        } else if (currentLanguage === 'cn') {
            return blog.translation.description_cn;
        } else if (currentLanguage === 'jp') {
            return blog.translation.description_jp;
        } else if (currentLanguage === 'my') {
            return blog.translation.description_my;
        } else {
            return blog.translation.description_en;  // Fallback to English if language is not supported
        }
    };

    return (
        <div className="blog-container">
            <div className="body-container">
                <div className="title-container"><h1 className="main-title">{t('blogs')}</h1></div>
                <div className="grid-container">
                    {
                        blogs.map(blog => (
                            <Link className="card-container" key={blog.id} to={`/blog/${blog.id}`}>
                                <div className="content-container">
                                    <div className="image-container" style={{ backgroundImage: `url("${blog.imageUrl || '/images/default/latest-news.png'}")` }} />
                                    <div className="text-container">
                                        <div className="title">{getBlogTitle(blog)}</div>
                                        <div className="description">{getBlogDescription(blog)}</div>
                                        <div className="date-container">
                                            <span className="material-icons">watch_later</span>
                                            <p>{new Date(blog.created_at).toLocaleDateString()}</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))
                    }
                </div>
                <div className="read-more-container">
                    <Link className="button-link" to="/blogs">
                        <p>{t('read_more')}</p>
                        <div className="material-icons-outlined">navigate_next</div>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Blog;
