import { Link } from "react-router-dom"
import { PathList } from "../../data/nav-link.data"
import './not-found.styles.scss';
import Navbar from "../../components/shared/navbar/navbar.component";

const NotFound = () => {
    return (
        <>
            <Navbar />
            <div className="not-found-container">
                <div className="body-container">
                    <div className="text-container">
                        <h1>Page Not Found.</h1>
                    </div>
                    <Link to={PathList.home.url} className="button-link">Back to home</Link>
                </div>
            </div>
        </>
    )
}

export default NotFound