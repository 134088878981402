
export const dropdownFade = {
    start: { opacity: 0, y: "3rem" },
    end: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: "3rem"  },
    transition: {
        duration: 0.3,
        ease: [0.9, 0, 0.1, 1], 
    }
}
